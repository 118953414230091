// @ts-check
/* eslint-disable react/prop-types */
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react';

import { SourceParticipantOfferType } from '../../SourceParticipantOffers/Context';
import { useDeviceRequests } from '../useDeviceRequests';
import { useMediaAudioFiltersPerformer } from '../MediaAudioFilters/useMediaAudioFiltersPerformer';
import { useMediaAudioFilters } from '../MediaAudioFilters/Provider';

/**
 * @import {
 * 	GetMediastream,
 * 	MediaStreamTrackOffer,
 * 	UseDeviceRequestsResult,
 * } from '../useDeviceRequests';
 */

/** @typedef {typeof SourceParticipantOfferType.AUDIOSHARE} AudioshareType */

/**
 * @typedef {MediaStreamTrackOffer<AudioshareType>} MediaStreamTrackAudioshare
 */

/**
 * @typedef {{
 * audioshareActiveTracks: UseDeviceRequestsResult<AudioshareType>['activeTracks'],
 * deviceRequestStates: UseDeviceRequestsResult<AudioshareType>['deviceRequestStates'],
 * requestAudioshare: UseDeviceRequestsResult<AudioshareType>['requestDevice'],
 * stopAudioshare: UseDeviceRequestsResult<AudioshareType>['stopDevice'],
 * }} IMediaShareAudioContext
 */

const MediaShareAudioContext = createContext(/** @type {IMediaShareAudioContext} */({}));

export const useMediaShareAudio = () => useContext(MediaShareAudioContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * 	disabled?: boolean,
 * }} MediaShareAudioProps
 */

export const MediaShareAudio = (
	/** @type {MediaShareAudioProps} */
	{
		children,
		disabled = false,
	},
) => {
	const getMediastream = useCallback(
		/** @type {GetMediastream<AudioshareType>} */
		async (deviceRequest) => {
			const mediastream = await navigator.mediaDevices.getUserMedia({
				video: false,
				audio: {
					deviceId: { exact: deviceRequest.physicalDeviceId },
					autoGainControl: false,
					channelCount: 2,
					echoCancellation: false,
					latency: 0,
					noiseSuppression: false,
					sampleRate: 48000,
					sampleSize: 16,
					volume: 1.0,
				},
			});
			return mediastream;
		},
		[],
	);

	const deviceRequests = useDeviceRequests({
		disabled,
		getMediastream,
		sourceParticipantType: SourceParticipantOfferType.AUDIOSHARE,
	});

	const {
		audioFiltersConfig,
		addAudioFiltersTracks,
		removeAudioFiltersTracks,
	} = useMediaAudioFilters();

	const { audioFiltersTracks } = useMediaAudioFiltersPerformer({
		audioFiltersConfig,
		audioTracks: deviceRequests.activeTracks,
	});

	useEffect(() => {
		if (audioFiltersTracks && deviceRequests.activeTracks) {
			addAudioFiltersTracks(audioFiltersTracks);
		}

		if (audioFiltersTracks.length !== deviceRequests.activeTracks?.length) {
			removeAudioFiltersTracks(audioFiltersTracks);
		}
		return undefined;
	}, [
		addAudioFiltersTracks,
		audioFiltersTracks,
		removeAudioFiltersTracks,
		audioFiltersConfig,
		deviceRequests.activeTracks,
	]);

	const activeTracks = /** @type {UseDeviceRequestsResult<AudioshareType>['activeTracks']} */(
		useMemo(() => [
			...(deviceRequests.activeTracks || [])
				.map((track) => (audioFiltersTracks || [])
					.find(({ sourceOffer }) => sourceOffer.id === track.sourceOffer?.id) || track),
		], [deviceRequests.activeTracks, audioFiltersTracks]));

	const value = useMemo(() => ({
		audioshareActiveTracks: activeTracks,
		deviceRequestStates: deviceRequests.deviceRequestStates,
		requestAudioshare: deviceRequests.requestDevice,
		stopAudioshare: deviceRequests.stopDevice,
	}), [deviceRequests, activeTracks]);

	return (
		<MediaShareAudioContext.Provider value={value}>
			{children}
		</MediaShareAudioContext.Provider>
	);
};
