/* eslint-disable react/prop-types */
// @ts-check
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	getSimulcastEncodings,
} from '@technomiam/soup-client';

import {
	connect,
	join as joinSoup,
	disconnect,
	soupSession,
	publishTrack,
	updateTrack,
	unpublishTrack,
} from '../../api/soup';
import { useReactVideo } from './Provider';
import { useMediaShareScreen } from '../Media/Share/Screen';
import { useMediaShareAudio } from '../Media/Share/Audio';
import { useMediaShareVideo } from '../Media/Share/Video';
import { useMediaShareImage } from '../Media/Share/Image';
import { useMediaKey } from '../Media/MediaKey/Provider';
import { usePrevious } from '../../lib/hooks';
import { KeyReplacementMode } from '../Media/MediaKey/KeyConfig';
import { useMediaTalkback } from '../Media/Talkback/Talkback';
import { getComputerId } from '../../lib/computerId';
import { useStudioSourceSettings } from '../StudioSourceSettings/Context';
import { SOURCE_TYPE_PARTICIPANT } from '../../lib/stream';

/**
 * @import { SourceParticipantOfferTrack } from '../SourceParticipantOffers/Context';
 * @import { MediaStreamTrackAudioshare } from '../Media/Share/Audio';
 * @import { MediaStreamTrackVideo } from '../Media/Share/Video';
 * @import { MediaStreamTrackImage } from '../Media/Share/Image';
 * @import { MediaStreamTrackScreenshare } from '../Media/Share/Screen';
 * @import { MediaStreamTrackUserOrCropOrKey } from '../Media/MediaKey/Provider';
 * @import { TalkbackMediaStreamTrack } from '../Media/Talkback/Talkback';
 * @import { Publication } from '../Soup/Context';
 */

//TODO CHECK
const { rollbar } = window;

/**
 * @typedef {{
 * 	connectingState: string,
 * 	getIsConnected: () => boolean,
 * 	hashtag?: string,
 * 	isConnected: boolean,
 * 	join: (hashtag: string) => void,
 * 	leave: () => void,
 * 	soupId?: string,
 * }} ISoupContext
 */

export const SoupContext = createContext(/** @type {ISoupContext} */({}));

export const useSoup = () => useContext(SoupContext);

export const SOUP_CONNECTING_STATE_CONNECTED = 'SOUP_CONNECTING_STATE_CONNECTED';
export const SOUP_CONNECTING_STATE_DISCONNECTED = 'SOUP_CONNECTING_STATE_DISCONNECTED';
export const SOUP_CONNECTING_STATE_PENDING = 'SOUP_CONNECTING_STATE_PENDING';

/**
 * @typedef {{
 * 		children: React.ReactNode,
 * 		isController?: boolean,
 * }} SoupProviderProps
 */

const computerId = getComputerId();

export const SoupProvider = (
	/** @type {SoupProviderProps} */
	{
		children,
		isController = false,
	},
) => {
	const { getConnectionConfig, user } = useReactVideo();
	const [hashtag, setHashtag] = useState(/** @type {string | undefined} */(undefined));
	const [isConnected, setIsConnected] = useState(false);
	const [connectingState, setConnectingState] = useState(SOUP_CONNECTING_STATE_DISCONNECTED);
	const cancelled = useRef(false);
	const [soupId, setSoupId] = useState(/** @type {string | undefined} */(undefined));

	const getIsConnected = useCallback(() => (
		isConnected && !!soupSession()?.connected
	), [isConnected]);

	const leave = useCallback(async () => {
		setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		cancelled.current = true;
		console.log('[leaveSoup]');
		disconnect();
	}, []);

	const join = useCallback(async (
		/** @type {string} */h,
	) => {
		try {
			setHashtag(h);
			cancelled.current = false;
			console.log('[joinSoup]');
			setConnectingState(SOUP_CONNECTING_STATE_PENDING);
			const connectionConfig = await getConnectionConfig();
			if (cancelled.current) return;
			await connect(connectionConfig, h);
			if (cancelled.current) return;
			await joinSoup();
			if (cancelled.current) return;
			setConnectingState(SOUP_CONNECTING_STATE_CONNECTED);
		} catch {
			setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		}
	}, [getConnectionConfig]);

	const { screenshareOrAudioFiltersActiveTracks } = useMediaShareScreen();
	const { audioshareActiveTracks } = useMediaShareAudio();

	const { videoshareActiveTracks } = useMediaShareVideo();
	const { imageshareActiveTracks } = useMediaShareImage();
	const {
		keyOrUserActiveTracks,
		config: keyConfig,
		configOverride: keyConfigOverride,
	} = useMediaKey();
	const { recipientUsers, talkbackActiveTrack } = useMediaTalkback();

	const previousKeyConfig = usePrevious(keyConfig);
	const previousKeyConfigOverride = usePrevious(keyConfigOverride);
	const previousRecipientUsers = usePrevious(recipientUsers);

	const { getSettingsBySource } = useStudioSourceSettings();

	/**
	 * @type {({
	 *  id: string,
	 *  mediaStreamTrack: MediaStreamTrackAudioshare
	 * 		| MediaStreamTrackVideo
	 * 		| MediaStreamTrackImage
	 * 		| MediaStreamTrackScreenshare
	 * 		| MediaStreamTrackUserOrCropOrKey
	 *      | TalkbackMediaStreamTrack,
	 * 	isKeyTrack?: boolean,
	 * 	keyConfig?: any,
	 * 	keyConfigOverride?: any,
	 * 	preventLarsens?: boolean,
	 * 	previousPreventLarsens?: boolean,
	 *  talkback?: {
	 *  	senderUserId: string,
	 * 		receiverUserIds: string[],
	 * 	},
	 * })[]}
	 */
	const localTracks = useMemo(() => {
		const selectedTracks = keyOrUserActiveTracks
			.filter((track) => !!track) // useful ?
			// Remove tracks that are used in talkback
			.filter((track) => talkbackActiveTrack?.originalTrackId !== track.id)
			.map((mediaStreamTrack) => {
				/**
				 * @type {{
				 *  id: string,
				* 	mediaStreamTrack: MediaStreamTrackUserOrCropOrKey,
				 *  isKeyTrack?: boolean,
				 *  keyConfig?: any,
				 *  keyConfigOverride?: any,
				 * }}
				 */
				const selectedTrack = { id: mediaStreamTrack.id, mediaStreamTrack };
				if (mediaStreamTrack.isKey && mediaStreamTrack.configId === 0) {
					selectedTrack.isKeyTrack = true;
					selectedTrack.keyConfig = keyConfig;
					selectedTrack.keyConfigOverride = keyConfigOverride;
				}
				return selectedTrack;
			});

		const formattedTalkbackTrack = talkbackActiveTrack ? {
			id: talkbackActiveTrack.id,
			mediaStreamTrack: talkbackActiveTrack,
			talkback: {
				senderUserId: user.sub,
				receiverUserIds: recipientUsers.map((u) => u._id),
			},
		} : null;

		const formatTrack = (
			/**
			 * @type {MediaStreamTrackAudioshare
			 * | MediaStreamTrackVideo
			 * | MediaStreamTrackImage
			 * | MediaStreamTrackScreenshare}
			 */mediaStreamTrack,
		) => ({ id: mediaStreamTrack.id, mediaStreamTrack });

		const publications = /** @type {Publication[]} */(
			Array.from(soupSession()?.publications.values() || [])
		);

		return [
			...selectedTracks,
			...(formattedTalkbackTrack ? [formattedTalkbackTrack] : []),
			...audioshareActiveTracks.map(formatTrack),
			...videoshareActiveTracks.map(formatTrack),
			...imageshareActiveTracks.map(formatTrack),
			...screenshareOrAudioFiltersActiveTracks.map(formatTrack),
		].map((localTrack) => {
			if (
				'talkback' in localTrack
				|| localTrack.mediaStreamTrack.kind !== 'audio'
			) return localTrack;
			const { mediaStreamTrack } = localTrack;
			const { sourceOffer } = mediaStreamTrack;
			const preventLarsens = !(getSettingsBySource({
				id: sourceOffer.id,
				type: SOURCE_TYPE_PARTICIPANT,
			})?.settings?.monitor);
			const currentPublication = publications.find(
				(p) => p.appData.trackId === mediaStreamTrack.id,
			);
			// If the publication is not found then we se the previous value
			// as the same to the new so we dont call updateTrack
			const previousPreventLarsens = currentPublication
				? currentPublication.appData.preventLarsens
				: preventLarsens;
			return {
				...localTrack,
				preventLarsens,
				previousPreventLarsens,
			};
		});
	}, [
		getSettingsBySource,
		keyConfig,
		keyConfigOverride,
		screenshareOrAudioFiltersActiveTracks,
		keyOrUserActiveTracks,
		audioshareActiveTracks,
		videoshareActiveTracks,
		imageshareActiveTracks,
		recipientUsers,
		talkbackActiveTrack,
		user,
	]);

	// console.log({
	// 	keyOrUserActiveTracks,
	// 	videoshareActive,
	// 	videoshareActiveTracks,
	// 	imageshareActive,
	// 	imageshareActiveTracks,
	// 	audioshareActiveTracks,
	// 	localTracks,
	// });

	useEffect(() => {
		if (connectingState === SOUP_CONNECTING_STATE_CONNECTED) {
			const soup = soupSession();
			if (!soup) {
				setIsConnected(false);
				return undefined;
			}

			const onConnected = () => {
				setIsConnected(true);
				setSoupId(soup.signaling.id);
			};
			const onDisconnected = () => { setIsConnected(false); };
			const onTransportFailed = async ({ rtcPeerConnection, transport }) => {
				// eslint-disable-next-line no-underscore-dangle
				const stats = Object.fromEntries(await transport.getStats());
				if (rollbar) {
					rollbar.error(
						'Transport connection failed.',
						{
							// eslint-disable-next-line no-underscore-dangle
							remoteSdpIceCandidates: transport._remoteSdp?._iceCandidates,
							stats,
							transport: {
								id: transport.id,
								direction: transport.direction,
							},
						},
					);
				}
				console.error('Soup transport failed', { rtcPeerConnection, transport, stats });
			};
			soup.on('connected', onConnected);
			soup.on('disconnected', onDisconnected);
			soup.on('transport:failed', onTransportFailed);

			setIsConnected(!!soup.connected);

			return () => {
				setIsConnected(false);
				soup.off('connected', onConnected);
				soup.off('disconnected', onDisconnected);
				soup.off('transport:failed', onTransportFailed);
			};
		}
		return undefined;
	}, [connectingState, user]);

	useEffect(() => {
		if (isConnected && soupSession()?.connected) {
			// console.log({ localTracks });
			const currentPublishedLocalTracksIds = Array.from(soupSession()?.productions.keys() || []);
			const localTracksIds = localTracks.map(({ id }) => id);
			// TODO: remplacer ce check par une comparaison avec les pulications
			// actuelles en utilisant par exemple JSON.stringify sur le subset de
			// appData que l'on veut comparer.
			const tracksToUpdate = localTracks.filter((localTrack) => (
				currentPublishedLocalTracksIds.includes(localTrack.id)
				&& (
					(
						localTrack.isKeyTrack
						&& (
							localTrack.keyConfig !== previousKeyConfig
							|| localTrack.keyConfigOverride !== previousKeyConfigOverride
						)
					)
					|| (
						localTrack.preventLarsens !== localTrack.previousPreventLarsens
					)
					|| (
						localTrack.talkback && (
							localTrack.talkback?.receiverUserIds !== previousRecipientUsers?.map((u) => u._id)
						)
					)
				)
			));
			// publish new ones
			const idsTracksToPublish = localTracksIds.filter(
				(lms) => !currentPublishedLocalTracksIds.includes(lms),
			);

			/**
			 * @param {SourceParticipantOfferTrack & {
			 * 	firefoxFixCaptureSettings?: MediaTrackSettings,
			 * }} track
			 * @returns {ReturnType<typeof getSimulcastEncodings | undefined>}
			 */
			const getTrackEncodings = (track) => {
				if (track.kind !== 'video') return undefined;

				// TODO: test with max bitrate (see below)
				if (track.device.kind === 'videoscreen') return undefined;

				// Firefox MediaStreamTrack.getSettings returns an empty object in case of captureStream
				// See Videoshare component for the fix
				const { height } = track.firefoxFixCaptureSettings || track.getSettings();
				const trackEncodings = getSimulcastEncodings(height);
				// TODO: test with max bitrate
				// if (trackType === TRACK_TYPE_SCREENSHARE) {
				// 	// Simulcast disabled for screensharing return only highest encoding
				// 	return trackEncodings.slice(-1);
				// }
				return trackEncodings;
			};
			console.log({ idsTracksToPublish });

			const getTrackAppDataKeyConfig = (kc) => {
				if (
					!kc
					|| kc.replacement.mode !== KeyReplacementMode.TRANSPARENT
				) return null;
				return {
					color: kc.replacement.transparentColor,
					sensitivity: kc.replacement.transparentColorSensitivity,
				};
			};

			/**
			 * @param {SourceParticipantOfferTrack} track
			 * @param {boolean | undefined} preventLarsens
			 * @returns {boolean}
			 */
			const getPreventLarsens = (
				track,
				preventLarsens,
			) => {
				if (track.kind === 'video') return false;
				return !!preventLarsens;
			};

			idsTracksToPublish.forEach((trackId) => {
				const localTrack = localTracks.find(({ id }) => id === trackId);
				if (!localTrack) return;
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					preventLarsens,
					talkback,
				} = localTrack;
				// TODO: type localTracks
				// eslint-disable-next-line prefer-destructuring
				const mediaStreamTrack = localTrack.mediaStreamTrack;

				const isSourceParticipantTrack = 'sourceOffer' in mediaStreamTrack;

				const configId = isSourceParticipantTrack ? mediaStreamTrack.configId : undefined;
				const device = isSourceParticipantTrack ? mediaStreamTrack.device : undefined;
				const sourceStreamType = isSourceParticipantTrack
					? mediaStreamTrack.sourceOffer.subType
					: undefined;
				const trackPreventLarsens = isSourceParticipantTrack
					? getPreventLarsens(mediaStreamTrack, preventLarsens)
					: preventLarsens;
				const trackEncodings = isSourceParticipantTrack
					? getTrackEncodings(mediaStreamTrack)
					: undefined;
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);
				const formattedUser = {
					avatar: user.picture,
					nickname: user.nickname,
					userId: user.sub,
				};
				console.log('PUBLISH', {
					alphaColor,
					computerId,
					configId,
					device,
					preventLarsens: trackPreventLarsens,
					talkback,
					sourceStreamType,
					user: formattedUser,
					mediaStreamTrack,
				});
				publishTrack(
					mediaStreamTrack,
					{
						alphaColor,
						computerId,
						configId,
						device,
						preventLarsens: trackPreventLarsens,
						sourceStreamType,
						trackId: mediaStreamTrack.id,
						talkback,
						user: formattedUser,
					},
					trackEncodings,
				);
			});

			tracksToUpdate.forEach((track) => {
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					preventLarsens,
					talkback,
				} = track;
				// TODO: type localTracks
				// eslint-disable-next-line prefer-destructuring
				const mediaStreamTrack = /** @type {SourceParticipantOfferTrack} */(track.mediaStreamTrack);
				const trackPreventLarsens = getPreventLarsens(
					mediaStreamTrack,
					preventLarsens,
				);
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);
				console.log('UPDATE', {
					preventLarsens: trackPreventLarsens,
					alphaColor,
					talkback,
					mediaStreamTrack,
				});
				updateTrack(mediaStreamTrack, {
					preventLarsens: trackPreventLarsens,
					alphaColor,
					talkback,
				});
			});

			// unpublish old ones
			const idsTracksToUnpublish = currentPublishedLocalTracksIds.filter(
				(cplt) => !localTracksIds.includes(cplt),
			);
			// console.log({ idsTracksToUnpublish });
			idsTracksToUnpublish.forEach((id) => {
				console.log('UNPUBLISH', { trackId: id });
				unpublishTrack({ id });
			});
		} else if (soupSession()?.productions) {
			const currentPublishedLocalTracksIds = Array.from(soupSession()?.productions.keys() || []);
			// console.log({ currentPublishedLocalTracksIds });
			currentPublishedLocalTracksIds.forEach((id) => {
				console.log('UNPUBLISH', { trackId: id });
				unpublishTrack({ id });
			});
		}
	}, [
		isController,
		keyConfig,
		previousKeyConfig,
		previousKeyConfigOverride,
		isConnected,
		localTracks,
		screenshareOrAudioFiltersActiveTracks,
		user,
		videoshareActiveTracks,
		imageshareActiveTracks,
		audioshareActiveTracks,
		talkbackActiveTrack,
		previousRecipientUsers,
	]);

	const contextValue = useMemo(() => ({
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
		soupId,
	}), [
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
		soupId,
	]);

	return (
		<SoupContext.Provider value={contextValue}>
			{children}
		</SoupContext.Provider>
	);
};
