/* eslint-disable react/prop-types */
//@ts-check
import { useCallback, useMemo, useState, createContext, useContext } from 'react';
import { useMediaUser } from '../User';

/**
 * @import { MediaStreamTrackUserOrCrop } from '../MediaCrop/Provider';
 * @import { ChannelMixerStatus } from '../../ReactVideo/Mixing';
 * @import { MediaStreamUser } from '../User';
 */

/**
 * @typedef {{
 * 	filters: {
 * 		balance: number,
 * 		equalizer: { value: number, label: string, freq: number }[],
 * 	},
 * 	source: { id: string, type: 'participant' },
 * }} AudioFiltersConfig
 */

/**
 * @typedef {{
 * handleCMSEvents: (cms: ChannelMixerStatus) => void,
 * audioFiltersOrUserAdudioActiveTracks: MediaStreamTrackUserOrCrop[],
 * replaceAudioTracks: (mediaStreams: MediaStreamUser[]) => MediaStreamUser[],
 * audioFiltersConfig: AudioFiltersConfig[],
 * addAudioFiltersTracks: (tracks: MediaStreamTrackUserOrCrop[]) => void,
 * removeAudioFiltersTracks: (tracks: MediaStreamTrackUserOrCrop[]) => void,
 * }} IMediaAudioFiltersContext
 */

export const MediaAudioFiltersContext = createContext(/** @type {IMediaAudioFiltersContext} */({}));

export const useMediaAudioFilters = () => useContext(MediaAudioFiltersContext);

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * }} MediaAudioFiltersProviderProps
 */

export const MediaAudioFiltersProvider = (
	/** @type {MediaAudioFiltersProviderProps} */
	{
		children,
	},
) => {
	const [audioFiltersAudioActiveTracks, setAudioFiltersAudioActiveTracks] = useState(
		/** @type {MediaStreamTrackUserOrCrop[]} */([]),
	);
	const [audioFiltersConfig, setAudioFiltersConfig] = useState(
		/** @type {AudioFiltersConfig[]} */([]),
	);

	const {
		userAudioActiveTracks,
	} = useMediaUser();

	const removeAudioFiltersTracks = useCallback(
		(/** @type {MediaStreamTrackUserOrCrop[]} */ tracks) => {
			setAudioFiltersAudioActiveTracks((prev) => {
				const relatedTracks = tracks.filter((track) => (
					prev.find((prevTrack) => (
						prevTrack.sourceOffer.id === track.sourceOffer.id
					))
				));
				if (relatedTracks.length > 0) {
					relatedTracks.forEach((track) => {
						track.stop();
					});
					const otherTracks = tracks.filter((track) => (
						prev.find((prevTrack) => (
							prevTrack.sourceOffer.id !== track.sourceOffer.id
						))
					));
					return [
						...otherTracks,
					];
				}
				return prev;
			});
		}, [],
	);

	const addAudioFiltersTracks = useCallback(
		(/** @type {MediaStreamTrackUserOrCrop[]} */ tracks) => {
			setAudioFiltersAudioActiveTracks((prev) => {
				const filteredTracks = tracks.filter((track) => (
					!prev.find((prevTrack) => (
						prevTrack.sourceOffer.id === track.sourceOffer.id
					))
				));
				if (filteredTracks.length > 0) {
					return [
						...prev,
						...filteredTracks,
					];
				}
				return prev;
			});
		}, [],
	);

	const handleCMSEvents = useCallback(
		(/** @type {ChannelMixerStatus} */ cms) => {
			const { audioFilters } = cms;
			setAudioFiltersConfig(audioFilters || []);
		}, [],
	);

	const replaceAudioTracks = useCallback(
		(/** @type {MediaStreamUser[]} */ mediaStreams) => {
			mediaStreams.forEach((mediaStream) => {
				const originalAudioTracks = mediaStream.getAudioTracks();
				if (originalAudioTracks.length > 0) {
					originalAudioTracks.forEach((track) => {
						const relatedAudioTracks = audioFiltersAudioActiveTracks.filter((audioTrack) => (
							audioTrack.sourceOffer.id === (/** @type {MediaStreamTrackUserOrCrop} */(track)
								.sourceOffer.id)
						));
						if (relatedAudioTracks.length > 0) {
							mediaStream.removeTrack(track);
							relatedAudioTracks.forEach((relatedAudioTrack) => {
								mediaStream.addTrack(relatedAudioTrack);
							});
						}
					});
				}
			});
			return mediaStreams;
		}, [audioFiltersAudioActiveTracks],
	);

	const isAudioFiltersEnabled = audioFiltersConfig?.length > 0;

	const audioFiltersOrUserAdudioActiveTracks = useMemo(() => {
		// ensure user track is removed even if key track is not yet available
		// in order to avoid publish two tracks in a row
		if (isAudioFiltersEnabled) {
			return audioFiltersAudioActiveTracks;
		}
		return userAudioActiveTracks;
	}, [isAudioFiltersEnabled, audioFiltersAudioActiveTracks, userAudioActiveTracks]);

	const context = useMemo(() => ({
		audioFiltersOrUserAdudioActiveTracks,
		audioFiltersConfig,
		addAudioFiltersTracks,
		removeAudioFiltersTracks,
		handleCMSEvents,
		replaceAudioTracks,
	}), [
		audioFiltersOrUserAdudioActiveTracks,
		audioFiltersConfig,
		addAudioFiltersTracks,
		removeAudioFiltersTracks,
		handleCMSEvents,
		replaceAudioTracks,
	]);

	return (
		<MediaAudioFiltersContext.Provider value={context}>
			{children}
		</MediaAudioFiltersContext.Provider>
	);
};
