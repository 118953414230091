/* eslint-disable react/prop-types */
//@ts-check
import { useEffect } from 'react';

import { useMediaAudioFilters, MediaAudioFiltersProvider } from './Provider';
import { useMediaAudioFiltersPerformer } from './useMediaAudioFiltersPerformer';
import { useMediaUser } from '../User';

const MediaAudioFiltersPerformer = () => {
	const {
		addAudioFiltersTracks,
		removeAudioFiltersTracks,
		audioFiltersConfig,
	} = useMediaAudioFilters();

	const { userAudioActiveTracks } = useMediaUser();

	const audioTracks = [
		...(userAudioActiveTracks || []),
	].filter((track) => (
		(audioFiltersConfig || []).find(({ source }) => source.id === track.sourceOffer?.id)
	));

	const { audioFiltersTracks } = useMediaAudioFiltersPerformer({
		audioFiltersConfig,
		audioTracks,
	});

	useEffect(() => {
		if (audioFiltersTracks && audioTracks) {
			addAudioFiltersTracks(audioFiltersTracks);
		}

		if (audioFiltersTracks.length !== audioTracks.length) {
			removeAudioFiltersTracks(audioFiltersTracks);
		}
		return undefined;
	}, [
		addAudioFiltersTracks,
		audioFiltersTracks,
		removeAudioFiltersTracks,
		audioTracks,
		audioFiltersConfig,
	]);

	return null;
};

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} AudioFiltersProps
 */

export const AudioFilters = (
	/** @type {AudioFiltersProps} */
	{
		children,
	},
) => (
	<MediaAudioFiltersProvider>
		<MediaAudioFiltersPerformer />
		{children}
	</MediaAudioFiltersProvider>
);
