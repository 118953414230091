/* eslint-disable react/prop-types */
// @ts-check
import {
	memo,
	createContext,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useInputs } from '../Inputs/Context';
import {
	DEFAULT_AUDIO_CONSTRAINTS,
	Resolution,
	stopTrack,
	USER_MAX_FPS,
} from './utils';
import getUserMedia from '../../lib/getUserMedia';
import { getRollbarInstance } from '../../lib/rollbar';
import { useSourceParticipantOffers, SourceParticipantOfferType, getTypeFilter } from '../SourceParticipantOffers/Context';

/**
 * @import {
 *  SourceParticipantOffer,
 *  SourceParticipantOfferDevice,
 * 	SourceParticipantOfferMediaDeviceKind,
 *  SourceParticipantOfferTrack,
 * } from '../SourceParticipantOffers/Context';
 * @import { VirtualDevice } from '../Inputs/Context';
 */

/*
	My advice: don't touch it unless this is already the end of world.
	And if so, keep in mind that Safari won't fight on your side...
*/

const rollbar = getRollbarInstance();

/**
 * @typedef {SourceParticipantOfferTrack<
 * 	typeof SourceParticipantOfferType.CONFIG> & {
 *  isCrop?: boolean,
 *  isKey?: boolean,
 *  physicalDeviceId: string,
 * }} MediaStreamTrackUser
 */

/**
 * @typedef {MediaStream & {
 *  configId: number,
 * }} MediaStreamUser
 */

/**
 * @typedef {{
 *  getDeviceTrack: (
 * 		physicalDeviceId: string,
 * 		kind: SourceParticipantOfferMediaDeviceKind,
 *  ) => MediaStreamTrackUser | undefined,
 *  getVirtualDeviceFromConfigAndKind: (
 * 		configId: number,
 * 		kind: SourceParticipantOfferMediaDeviceKind,
 * 	) => VirtualDevice | undefined,
 * 	inputDeviceStatuses: { [physicalDeviceId: string]: InputDeviceStatus },
 * 	requestInputDevice: (
 * 		deviceRequest: DeviceRequest & { physicalDeviceId: string }
 * 	) => Promise<MediaStreamTrackUser[] | undefined>,
 * 	resetInputDeviceStatusById: (physicalDeviceId: string) => void,
 * 	stopInputDevice: (deviceRequest: DeviceRequest | OrphanDeviceRequest) => void,
 * 	userActiveTracks: MediaStreamTrackUser[],
 * 	userAudioActiveTracks: MediaStreamTrackUser[],
 * 	userMediastreams: MediaStreamUser[],
 * 	userVideoActiveTracks: MediaStreamTrackUser[],
 * }} IMediaUserContext
 */

export const MediaUserContext = createContext(/** @type {IMediaUserContext} */({}));

export const useMediaUser = () => useContext(MediaUserContext);

/**
 * @typedef {{
 * 	allowAudio?: boolean,
 * 	allowVideo?: boolean,
 * 	children: React.ReactNode,
 *  resolution?: Resolution,
 * }} MediaUserProps
 */

/**
 * @typedef {{
 *  deviceRequest: DeviceRequest,
 *  error: Error | undefined,
 *  status: 'disabled' | 'prompt' | 'granted' | 'denied' | 'error',
 * }} InputDeviceStatus
 */

/**
 * @typedef {MediaTrackConstraints
 * 	& { deviceId: { exact: string } }} UserMediaTrackConstraints
 */

/**
 * @typedef {{
 *  device: SourceParticipantOfferDevice<typeof SourceParticipantOfferType.CONFIG>,
 *  physicalDeviceId: string,
 *  source: SourceParticipantOffer,
 * }} DeviceRequest
 */

/**
 * @typedef {Omit<DeviceRequest, 'source'>} OrphanDeviceRequest
 */

/**
 *
 * @param {string} deviceId
 * @param {number} res
 * @returns {UserMediaTrackConstraints}
 */
const getUserMediaVideoConstraints = (deviceId, res) => ({
	deviceId: { exact: deviceId },
	height: { ideal: res },
	frameRate: { ideal: USER_MAX_FPS }, // max: USER_MAX_FPS (24) is not supported by Firefox
	aspectRatio: { ideal: 16 / 9 },
});

/**
 * @param {string} deviceId
 * @param {Omit<MediaTrackConstraints, 'deviceId'>} [constraints]
 * @returns {UserMediaTrackConstraints}
 */
const getUserMediaAudioConstraints = (deviceId, constraints = {}) => ({
	deviceId: { exact: deviceId },
	...DEFAULT_AUDIO_CONSTRAINTS,
	...constraints,
});

/**
 *
 * @param {number} configId
 * @param {'audioinput' | 'videoinput'} deviceKind
 * @returns {{
 * 	configId: number,
 *  device: VirtualDevice | undefined,
 *  deviceId: string | undefined,
 *  deviceTrack: MediaStreamTrackUser | undefined,
 *  error: Error | undefined,
 *  inputDeviceStatus: InputDeviceStatus | undefined,
 *  isActive: boolean,
 *  isLoading: boolean,
 *  permission: import('../Inputs').PermissionStatusState | undefined,
 *  toggleInputDevice: () => void,
 * }}
 */
export const useDeviceStatusFromConfig = (configId, deviceKind) => {
	const {
		getDeviceTrack,
		getVirtualDeviceFromConfigAndKind,
		inputDeviceStatuses,
		resetInputDeviceStatusById,
	} = useMediaUser();
	const {
		activateAudioInput,
		activateVideoInput,
		deactivateAudioInput,
		deactivateVideoInput,
		inputPermissions,
	} = useInputs();

	const device = getVirtualDeviceFromConfigAndKind(configId, deviceKind);
	const deviceId = device?.virtualDeviceId;
	const physicalDeviceId = device?.physicalDeviceId;
	const deviceTrack = physicalDeviceId ? getDeviceTrack(physicalDeviceId, deviceKind) : undefined;
	const inputDeviceStatus = physicalDeviceId ? inputDeviceStatuses[physicalDeviceId] : undefined;
	const isActive = !!deviceTrack;
	const permission = inputPermissions[deviceKind];

	const toggleInputDevice = () => {
		if (isActive) {
			if (deviceKind === 'audioinput') {
				deactivateAudioInput(configId);
			} else {
				deactivateVideoInput(configId);
			}
		} else {
			if (physicalDeviceId) {
				resetInputDeviceStatusById(physicalDeviceId);
			}
			if (deviceKind === 'audioinput') {
				activateAudioInput(configId);
			} else {
				activateVideoInput(configId);
			}
		}
	};

	return {
		configId,
		device,
		deviceId,
		deviceTrack,
		error: inputDeviceStatus?.error,
		inputDeviceStatus,
		isActive: !!deviceTrack,
		isLoading: inputDeviceStatus?.status === 'prompt',
		permission,
		toggleInputDevice,
	};
};

const isSourceConfig = getTypeFilter(SourceParticipantOfferType.CONFIG);

// eslint-disable-next-line prefer-arrow-callback
export const MediaUser = memo(function MediaUser(
	/** @type {MediaUserProps} */
	{
		allowAudio = false,
		allowVideo = false,
		children,
		resolution = Resolution.P720,
	},
) {
	const {
		isDeviceUpdating,
		inputsConfig,
		inputDevices,
		inputPermissions,
	} = useInputs();
	const { sources } = useSourceParticipantOffers();
	const userSources = useMemo(
		() => sources.filter(isSourceConfig),
		[sources],
	);

	const [userAudioActiveTracks, setUserAudioActiveTracks] = useState(
		/** @type {IMediaUserContext['userAudioActiveTracks']} */([]),
	);
	const [userVideoActiveTracks, setUserVideoActiveTracks] = useState(
		/** @type {IMediaUserContext['userVideoActiveTracks']} */([]),
	);
	const userActiveTracks = useMemo(
		() => [...userAudioActiveTracks, ...userVideoActiveTracks],
		[userAudioActiveTracks, userVideoActiveTracks],
	);

	const userMediastreamsRef = useRef(
		/** @type {MediaStreamUser[]}*/([]),
	); // memoize mediastreams

	const userMediastreams = useMemo(() => {
		if (userActiveTracks.length > 0) {
			const mediaStreams = userSources
				.map((userSource) => {
					const tracks = userActiveTracks.filter((track) => track.configId === userSource.configId);
					if (tracks.length <= 0) return undefined;

					const memoizedMediastream = userMediastreamsRef.current.find(
						(m) => m.configId === userSource.configId,
					);
					const memoizedMediastreamTracks = memoizedMediastream?.getTracks() || [];
					if (
						tracks.length === memoizedMediastreamTracks?.length
						&& tracks.every((track) => memoizedMediastreamTracks.includes(track))
					) return memoizedMediastream; // return memoized mediastream if tracks are the same

					// Refresh mediastream when tracks change to avoid player image stuck
					const newMediaStream = /** @type {MediaStreamUser} */(new MediaStream(tracks));
					// TODO: avoid cast
					newMediaStream.configId = /** @type {number} */(userSource.configId);
					return newMediaStream;
				})
				// remove undefined mediastreams (if no tracks per configId)
				.filter((/** @type {MediaStreamUser | undefined} */m) => !!m);

			userMediastreamsRef.current = mediaStreams;
			return mediaStreams;
		}
		return [];
	}, [userActiveTracks, userSources]);

	const userActiveTracksRef = useRef(userActiveTracks);
	userActiveTracksRef.current = userActiveTracks;
	const unmountedRef = useRef(false);

	// cleanup
	useEffect(() => () => {
		unmountedRef.current = true;
		userActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const getDeviceTrack = useCallback(
		/** @type {IMediaUserContext['getDeviceTrack']} */
		(physicalDeviceId, deviceKind) => (
			userActiveTracks?.find((track) => {
				const kind = deviceKind === 'audioinput' ? 'audio' : 'video';
				return (
					track.physicalDeviceId === physicalDeviceId
					&& track.kind === kind
				);
			})
		),
		[userActiveTracks],
	);

	const getVirtualDeviceFromConfigAndKind = useCallback(
		/** @type {IMediaUserContext['getVirtualDeviceFromConfigAndKind']} */
		(configId, kind) => {
			const inputConfig = inputsConfig.find((cfg) => cfg.id === configId);
			if (!inputConfig) return undefined;
			let virtualDeviceId;
			if (kind === 'audioinput') {
				virtualDeviceId = inputConfig.audioInputId;
			} else if (kind === 'videoinput') {
				virtualDeviceId = inputConfig.videoInputId;
			}
			if (!virtualDeviceId) return undefined;
			return inputDevices.find((d) => d.virtualDeviceId === virtualDeviceId);
		},
		[inputsConfig, inputDevices],
	);

	const addTrack = useCallback((
		/** @type {MediaStreamTrackUser} */track,
	) => {
		if (track.kind === 'audio') {
			setUserAudioActiveTracks((state) => [
				...(state.filter((t) => t.id !== track.id) || []),
				track,
			]);
		} else if (track.kind === 'video') {
			setUserVideoActiveTracks((state) => [
				...(state.filter((t) => t.id !== track.id) || []),
				track,
			]);
		} else {
			throw new Error(`addTrack: Unknown kind '${track.kind}'`);
		}
	}, []);

	const requestUserMedia = useCallback(
		/**
		 * @param {{
		 *  audio?: UserMediaTrackConstraints,
		 *  video?: UserMediaTrackConstraints,
		 * }} constraints
		 * @param {DeviceRequest} deviceRequest
		 * @returns {Promise<MediaStreamTrackUser[]>}
		 */
		async (constraints, deviceRequest) => {
			const mediastream = await getUserMedia(constraints);
			if (unmountedRef.current) {
				mediastream.getTracks().forEach((track) => track.stop());
				return [];
			}
			const tracks = /** @type {MediaStreamTrackUser[]} */(mediastream.getTracks());
			tracks.forEach((track) => {
				track.configId = deviceRequest.source.configId;
				track.device = { ...deviceRequest.device };
				track.physicalDeviceId = deviceRequest.physicalDeviceId;
				track.sourceOffer = { ...deviceRequest.source };
				addTrack(track);
			});
			return tracks;
		},
		[addTrack],
	);

	const [inputDeviceStatuses, setInputDeviceStatuses] = useState(
		/** @type {IMediaUserContext['inputDeviceStatuses']} */({}),
	);

	const setInputDeviceStatus = useCallback(
		/**
		 * @param {DeviceRequest} deviceRequest
		 * @param {InputDeviceStatus['status']} status
		 * @param {InputDeviceStatus['error']} [error]
		 */
		(deviceRequest, status, error) => {
			setInputDeviceStatuses((prevState) => ({
				...prevState,
				[deviceRequest.physicalDeviceId]: {
					deviceRequest,
					status,
					error,
				},
			}));
		},
		[],
	);

	const resetInputDeviceStatusById = useCallback(
		/** @type {IMediaUserContext['resetInputDeviceStatusById']} */
		(physicalDeviceId) => {
			setInputDeviceStatuses((prevState) => {
				const { [physicalDeviceId]: _, ...rest } = prevState;
				return rest;
			});
		},
		[],
	);

	const resolutionRef = useRef(resolution);
	resolutionRef.current = resolution;

	const requestInputDevice = useCallback(
		/** @type {IMediaUserContext['requestInputDevice']} */
		async (deviceRequest) => {
			const { device, physicalDeviceId } = deviceRequest;
			if (device.kind !== 'audioinput' && device.kind !== 'videoinput') {
				throw new Error('Incorrect input devide kind');
			}

			const constraints = {};
			if (device.kind === 'audioinput') {
				constraints.audio = getUserMediaAudioConstraints(
					physicalDeviceId,
					{
						echoCancellation: { ideal: true },
						noiseSuppression: { ideal: true },
					},
				);
			}
			if (device.kind === 'videoinput') {
				constraints.video = getUserMediaVideoConstraints(
					physicalDeviceId,
					resolutionRef.current, // Use the latest resolution.
					// Dont request a new mediatrack when resolution changes.
					// We will call applyConstraints() on existing track instead.
				);
			}

			setInputDeviceStatus(deviceRequest, 'prompt');
			try {
				const tracks = await requestUserMedia(constraints, deviceRequest);
				if (unmountedRef.current) {
					return undefined;
				}
				setInputDeviceStatus(deviceRequest, 'granted');
				return tracks;
			} catch (/** @type {any} */err) {
				// eslint-disable-next-line no-console
				console.error(err);
				if (rollbar) {
					rollbar.error(err, { constraints });
				}
				if (unmountedRef.current) {
					return undefined;
				}
				const error = err instanceof Error ? err : new Error('Unknown error');
				setInputDeviceStatus(deviceRequest, 'error', error);
			}
			return undefined;
		},
		[requestUserMedia, setInputDeviceStatus],
	);

	const stopInputDevice = useCallback(
		/** @type {IMediaUserContext['stopInputDevice']} */
		(deviceRequest) => {
			const tracks = userActiveTracks.filter((track) => (
				track.physicalDeviceId === deviceRequest.physicalDeviceId
				&& track.kind === (deviceRequest.device.kind === 'audioinput' ? 'audio' : 'video')
			));
			tracks.forEach((track) => {
				stopTrack(track);
				// Needs this for firefox because the event
				// "ended" is not handled when track is stopped manually
				if (track.kind === 'audio') {
					setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
				}
				if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
			});
			resetInputDeviceStatusById(deviceRequest.physicalDeviceId);
		},
		[resetInputDeviceStatusById, userActiveTracks],
	);

	/**
	 * This event is used to reset the input device status
	 * after an update of the input devices.
	 * Some tracks may have been stopped because of the update,
	 * so we need to restart it
	 */
	const handleDeviceFinishUpdating = () => {
		inputDevices
			.forEach((device) => {
				const {
					kind,
					physicalDeviceId,
				} = device;
				if (
					!physicalDeviceId
					|| (kind !== 'audioinput' && kind !== 'videoinput')) {
					return;
				}
				const inputDeviceStatus = inputDeviceStatuses[physicalDeviceId];
				const activeTrack = getDeviceTrack(physicalDeviceId, kind);
				if (!activeTrack && inputDeviceStatus?.status === 'granted') {
					resetInputDeviceStatusById(physicalDeviceId);
				}
			});
	};
	const handleDeviceFinishUpdatingRef = useRef(handleDeviceFinishUpdating);
	handleDeviceFinishUpdatingRef.current = handleDeviceFinishUpdating;

	const isDeviceUpdatingPreviousValueRef = useRef(isDeviceUpdating);

	useEffect(() => {
		const previousValue = isDeviceUpdatingPreviousValueRef.current;
		isDeviceUpdatingPreviousValueRef.current = isDeviceUpdating;
		if (!isDeviceUpdating && isDeviceUpdating !== previousValue) {
			handleDeviceFinishUpdatingRef.current();
		}
	}, [isDeviceUpdating]);

	const userSourceDeviceRequests = useMemo(() => userSources.reduce((acc, source) => {
		source.devices.forEach((device) => {
			const physicalDeviceId = inputDevices.find(
				(d) => d.virtualDeviceId === device.deviceId,
			)?.physicalDeviceId;

			if (!physicalDeviceId) return;

			acc = [
				...acc,
				{
					device,
					physicalDeviceId,
					source,
				},
			];
		});
		return acc;
	}, /** @type {DeviceRequest[]} */([])), [inputDevices, userSources]);

	useEffect(() => {
		if (isDeviceUpdating) {
			// Don't do anything while device list is updating
			return;
		}

		const orphanDeviceStatuses = Object.values(inputDeviceStatuses).filter((inputDeviceStatus) => {
			const { deviceRequest } = inputDeviceStatus;
			const device = userSourceDeviceRequests.find(
				(dr) => dr.physicalDeviceId === deviceRequest.physicalDeviceId,
			);
			return !device;
		});

		orphanDeviceStatuses.forEach((orphanDeviceStatus) => {
			stopInputDevice(orphanDeviceStatus.deviceRequest);
		});

		userSourceDeviceRequests.forEach((useSourceDeviceRequest) => {
			const { device, physicalDeviceId } = useSourceDeviceRequest;
			const {
				disabled,
				kind,
			} = device;

			if (kind !== 'audioinput' && kind !== 'videoinput') {
				return;
			}

			const enabled = !disabled;
			const inputPermissionGranted = inputPermissions[kind] === 'granted';
			const globalEnabled = kind === 'audioinput' ? allowAudio : allowVideo;

			const inputDeviceStatus = inputDeviceStatuses[physicalDeviceId];
			const activeTrack = getDeviceTrack(physicalDeviceId, kind);

			if (
				!enabled
				|| !globalEnabled
				|| !inputPermissionGranted
				|| !physicalDeviceId
			) {
				if (activeTrack) {
					stopInputDevice(useSourceDeviceRequest);
				}
				return;
			}

			if (
				!activeTrack
				&& !inputDeviceStatus?.status
			) {
				requestInputDevice(
					// Verified above that physicalDeviceId is defined
					/** @type {DeviceRequest & { physicalDeviceId: string }} */(useSourceDeviceRequest),
				);
			}
		});
	}, [
		allowAudio,
		allowVideo,
		getDeviceTrack,
		inputDeviceStatuses,
		inputPermissions,
		isDeviceUpdating,
		requestInputDevice,
		stopInputDevice,
		userSourceDeviceRequests,
	]);

	useEffect(() => {
		const handleTrackEnded = (/** @type {Event}*/{ target: track }) => {
			if (!(track instanceof MediaStreamTrack)) return;
			// Commented because it cause a infinite loop if the track is stopped
			// just after being started.
			// For example on safari, the audio track is stopped when we start another
			// audio device. So the previous device track is stopped and the useEffect
			// triggers a new request for the same device. Infinitely.
			// resetInputDeviceStatusById(track.deviceId);
			track.removeEventListener('trackended', handleTrackEnded);
			if (track.kind === 'audio') {
				setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
			}
			if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
		};

		userActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			userActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [
		userActiveTracks,
	]);

	const value = useMemo(() => ({
		getDeviceTrack,
		getVirtualDeviceFromConfigAndKind,
		inputDeviceStatuses,
		requestInputDevice,
		resetInputDeviceStatusById,
		stopInputDevice,
		userActiveTracks,
		userAudioActiveTracks,
		userMediastreams,
		userVideoActiveTracks,
	}), [
		getDeviceTrack,
		getVirtualDeviceFromConfigAndKind,
		inputDeviceStatuses,
		requestInputDevice,
		resetInputDeviceStatusById,
		stopInputDevice,
		userActiveTracks,
		userAudioActiveTracks,
		userMediastreams,
		userVideoActiveTracks,
	]);

	return (
		<MediaUserContext.Provider value={value}>
			{children}
		</MediaUserContext.Provider>
	);
});
