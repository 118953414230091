// @ts-check
import { useEffect, useMemo, useCallback, useState } from 'react';

/**
 * @import { MediaStreamTrackUserOrCrop } from '../MediaCrop/Provider';
 * @import { AudioFiltersConfig } from './Provider';
 */

const EQUALIZER_BANDS_HZ = [
	32,
	64,
	125,
	500,
	2000,
	4000,
	8000,
	16000,
];

/**
 * @typedef {MediaStreamTrackUserOrCrop & {
 * 	panner: StereoPannerNode,
 * 	equalizers: BiquadFilterNode[],
 * }} MediaStreamTrackUserOrCropWithPannerAndEqualizers
 */

/**
 * @typedef {{
 * 	audioFiltersConfig: AudioFiltersConfig[],
 * 	audioTracks: MediaStreamTrack[],
 * }} UseMediaAudioFiltersPerformerProps
 */

export const useMediaAudioFiltersPerformer = (
	/** @type {UseMediaAudioFiltersPerformerProps} */
	{
		audioFiltersConfig,
		audioTracks,
	},
) => {
	const [audioFiltersTracks, setAudioFiltersTracks] = useState(
		/** @type {MediaStreamTrackUserOrCropWithPannerAndEqualizers[]} */([]),
	);

	const createAudioFiltersTrack = useCallback((/** @type {MediaStreamTrackUserOrCrop} */ track) => {
		const audioContext = new AudioContext();
		const stream = new MediaStream([track]);
		const source = audioContext.createMediaStreamSource(stream);
		const destination = audioContext.createMediaStreamDestination();

		/** @type {BiquadFilterNode[]} */
		const equalizers = [];
		const panner = audioContext.createStereoPanner();
		panner.pan.value = 0;

		EQUALIZER_BANDS_HZ.forEach((freq) => {
			const filter = audioContext.createBiquadFilter();
			filter.type = 'peaking';
			filter.frequency.value = freq;
			filter.Q.value = 1;
			filter.gain.value = 0;
			equalizers.push(filter);
		});
		equalizers.reduce((prev, curr) => {
			prev.connect(curr);
			return curr;
		});

		source.connect(panner);
		panner.connect(equalizers[0]);
		equalizers[equalizers.length - 1].connect(destination);

		const outputTrack = /** @type {MediaStreamTrackUserOrCropWithPannerAndEqualizers} */(
			destination.stream.getAudioTracks()[0]
		);
		outputTrack.configId = track.configId;
		//outputTrack.id = track.id;
		//outputTrack.label = track.label;
		outputTrack.device = track.device;
		outputTrack.physicalDeviceId = track.physicalDeviceId;
		outputTrack.sourceOffer = track.sourceOffer;
		outputTrack.panner = panner;
		outputTrack.equalizers = equalizers;

		return outputTrack;
	}, []);

	const updateAudioFiltersTracks = useCallback((
		/** @type {MediaStreamTrackUserOrCropWithPannerAndEqualizers} */ track,
		/** @type {AudioFiltersConfig | undefined} */ config,
	) => {
		if (!config) return;
		if (track.panner) {
			track.panner.pan.value = config.filters.balance;
		}
		if (track.equalizers) {
			track.equalizers.forEach((equalizer) => {
				const relatedConfig = config.filters.equalizer.find(({ freq }) => (
					freq === equalizer.frequency.value
				));
				if (relatedConfig) {
					equalizer.gain.value = relatedConfig.value;
				}
			});
		}
	}, []);

	useEffect(() => {
		const tracksToDelete = (audioFiltersTracks || []).filter((track) => (
			!(audioTracks || []).find((audioTrack) => (
				// eslint-disable-next-line max-len
				/** @type {MediaStreamTrackUserOrCrop} */(audioTrack).sourceOffer.id === track.sourceOffer?.id
			))
		));
		if (tracksToDelete.length > 0) {
			setAudioFiltersTracks((prev) => prev.filter((track) => (
				!tracksToDelete.find(({ sourceOffer }) => (
					sourceOffer.id === track.sourceOffer?.id
				))
			)));
		}

		const tracksToCreate = /** @type {MediaStreamTrackUserOrCrop[]} */(audioTracks
			.filter((track) => (
				!(audioFiltersTracks || []).find(({ sourceOffer }) => (
					sourceOffer.id === (/** @type {MediaStreamTrackUserOrCrop} */(track).sourceOffer?.id)
				))
			)));

		const tracks = (tracksToCreate.map((track) => createAudioFiltersTrack(track)) || []);
		if (tracksToCreate.length > 0) {
			setAudioFiltersTracks((prev) => [
				...tracks,
				...(prev || []),
			]);
		}

		audioFiltersTracks.forEach((track) => {
			updateAudioFiltersTracks(
				track,
				audioFiltersConfig.find(({ source }) => source.id === track.sourceOffer?.id),
			);
		});
	}, [
		audioFiltersTracks,
		audioTracks,
		audioFiltersConfig,
		createAudioFiltersTrack,
		updateAudioFiltersTracks,
	]);

	return useMemo(
		() => ({
			audioFiltersTracks,
		}),
		[
			audioFiltersTracks,
		],
	);
};
