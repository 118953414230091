/* eslint-disable react/prop-types */
// @ts-check
import { useCallback, useMemo, useState, createContext, useContext, useEffect } from 'react';

import { defaultForcedKeyConfig, KeyDetectionMode } from './KeyConfig';
import { useMediaCrop } from '../MediaCrop/Provider';
import { useMediaAudioFilters } from '../MediaAudioFilters/Provider';

/**
 * @import { MediaStreamUser } from '../User';
 * @import { MediaStreamTrackUserOrCrop } from '../MediaCrop/Provider';
 * @import { ChannelMixerStatus } from '../../ReactVideo/Mixing';
 */

/**
 * @typedef {MediaStreamTrackUserOrCrop & {
 * 	isKey: true
 * }} MediaStreamTrackKey
 */

/**
 * @typedef {MediaStreamTrackUserOrCrop | MediaStreamTrackKey} MediaStreamTrackUserOrCropOrKey
 */

// TODO: type localConfig and configOverride

/**
 * @typedef {{
 * addKeyVideoTrack: (track: MediaStreamTrackKey) => void,
 * config: any,
 * configOverride: any,
 * handleCMSEvents: (cms: ChannelMixerStatus) => void,
 * isKeyForced: boolean,
 * isReplacementConfigOverriden: boolean,
 * removeKeyVideoTrackByTrack: (track: MediaStreamTrackKey) => void,
 * setKeyVideoActiveTracks: React.Dispatch<React.SetStateAction<MediaStreamTrackKey[]>>,
 * keyVideoActiveTracks: MediaStreamTrackKey[],
 * keyOrUserVideoActiveTracks: MediaStreamTrackUserOrCropOrKey[],
 * keyOrUserActiveTracks: MediaStreamTrackUserOrCropOrKey[],
 * keyOrUserMediastream: MediaStreamUser[],
 * }} IMediaKeyContext
 */

export const MediaKeyContext = createContext(/** @type {IMediaKeyContext} */({}));

export const useMediaKey = () => useContext(MediaKeyContext);

const LOCAL_KEY_CONFIG_KEY = 'beeyou_localKeyConfig';

const getLocalKeyConfig = () => {
	const localKeyConfig = localStorage.getItem(LOCAL_KEY_CONFIG_KEY);
	return localKeyConfig && JSON.parse(localKeyConfig);
};
const setLocalKeyConfig = (config) => localStorage.setItem(
	LOCAL_KEY_CONFIG_KEY,
	JSON.stringify(config),
);

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * 	isHost?: boolean,
 * }} MediaKeyProviderProps
 */

export const MediaKeyProvider = (
	/** @type {MediaKeyProviderProps} */
	{
		children,
		isHost = false,
	},
) => {
	// TODO : type localConfig
	const [localConfig, setLocalConfig] = useState(() => getLocalKeyConfig());
	const [keyVideoActiveTracks, setKeyVideoActiveTracks] = useState(
		/** @type {IMediaKeyContext['keyVideoActiveTracks']} */([]),
	);
	const {
		cropOrUserVideoActiveTracks,
		cropOrUserMediastreams,
	} = useMediaCrop();
	const {
		audioFiltersOrUserAdudioActiveTracks,
		replaceAudioTracks,
	} = useMediaAudioFilters();

	const [channelMixerKeyConfig, setChannelMixerKeyConfig] = useState();

	const configOverride = useMemo(() => {
		const replacementOverride = isHost
			? channelMixerKeyConfig?.own?.replacement
			: channelMixerKeyConfig?.all?.replacement;

		if (!replacementOverride) {
			return undefined;
		}

		return {
			...defaultForcedKeyConfig,
			replacement: replacementOverride,
		};
	}, [isHost, channelMixerKeyConfig]);

	const isReplacementConfigOverriden = !!configOverride;

	const isKeyForced = isReplacementConfigOverriden && isHost;

	// The 2-pass key is not available for the host.
	// For the host, the key settings defined in scenes completely
	// replace the his default key settings.
	const config = useMemo(() => {
		if (isKeyForced) return undefined;
		return localConfig;
	}, [isKeyForced, localConfig]);

	const addKeyVideoTrack = useCallback(
		/** @type {IMediaKeyContext['addKeyVideoTrack']}*/
		(track) => {
			setKeyVideoActiveTracks((prev) => [...prev, track]);
		}, [],
	);

	const removeKeyVideoTrackByTrack = useCallback(
		/** @type {IMediaKeyContext['removeKeyVideoTrackByTrack']}*/
		(track) => {
			setKeyVideoActiveTracks((prev) => prev.filter(({ configId }) => configId !== track.configId));
		}, [],
	);

	const handleCMSEvents = useCallback(
		/** @type {IMediaKeyContext['handleCMSEvents']}*/
		(cms) => {
			const { key } = cms;
			setChannelMixerKeyConfig(key);
		}, [],
	);

	const isKeyEnabled = (
		(
			!!config?.detection.mode
			&& config.detection.mode !== KeyDetectionMode.DISABLED
		)
		|| (
			!!configOverride?.detection.mode
			&& configOverride.detection.mode !== KeyDetectionMode.DISABLED
		)
	);

	const keyOrUserVideoActiveTracks = useMemo(() => {
		// ensure user track is removed even if key track is not yet available
		// in order to avoid publish two tracks in a row
		if (isKeyEnabled) {
			return [
				...keyVideoActiveTracks,
				...cropOrUserVideoActiveTracks.filter(({ configId }) => configId !== 0),
			];
		}
		return cropOrUserVideoActiveTracks;
	}, [isKeyEnabled, keyVideoActiveTracks, cropOrUserVideoActiveTracks]);

	const keyOrUserActiveTracks = useMemo(
		() => [
			...keyOrUserVideoActiveTracks,
			...audioFiltersOrUserAdudioActiveTracks,
		], [keyOrUserVideoActiveTracks, audioFiltersOrUserAdudioActiveTracks],
	);

	const keyOrUserMediastream = useMemo(() => {
		if (keyVideoActiveTracks?.length > 0) {
			const keyMediaStream = /** @type {MediaStreamUser} */(new MediaStream());
			keyMediaStream.configId = 0;
			keyMediaStream.addTrack(keyVideoActiveTracks[0]);
			const userMediaStream = cropOrUserMediastreams.find(({ configId }) => configId === 0);
			if (userMediaStream) {
				const track = userMediaStream.getAudioTracks()[0];
				if (track) keyMediaStream.addTrack(track);
			}

			// Refresh keyMediaStream when tracks change to avoid player image stuck
			const streams = [
				...cropOrUserMediastreams.filter(({ configId }) => configId !== 0),
				keyMediaStream,
			];
			return replaceAudioTracks(streams);
		}
		return replaceAudioTracks(cropOrUserMediastreams);
	}, [keyVideoActiveTracks, cropOrUserMediastreams, replaceAudioTracks]);

	useEffect(() => {
		setLocalKeyConfig(localConfig);
	}, [localConfig]);

	const context = useMemo(() => ({
		addKeyVideoTrack,
		config,
		configOverride,
		handleCMSEvents,
		isKeyForced,
		isReplacementConfigOverriden,
		localConfig,
		removeKeyVideoTrackByTrack,
		setLocalConfig,
		setKeyVideoActiveTracks,
		keyVideoActiveTracks,
		keyOrUserVideoActiveTracks,
		keyOrUserActiveTracks,
		keyOrUserMediastream,
	}), [
		addKeyVideoTrack,
		config,
		configOverride,
		handleCMSEvents,
		isKeyForced,
		isReplacementConfigOverriden,
		localConfig,
		removeKeyVideoTrackByTrack,
		keyVideoActiveTracks,
		keyOrUserVideoActiveTracks,
		keyOrUserActiveTracks,
		keyOrUserMediastream,
	]);

	return (
		<MediaKeyContext.Provider value={context}>
			{children}
		</MediaKeyContext.Provider>
	);
};
